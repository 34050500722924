<template>
  <BaseDialog :model-value="true" size-class="!max-w-3xl" @update:model-value="$emit('close')">
    <template #header>
      {{ modalTitle }}
    </template>
    <template #default>
      <div v-show="page == 1" class="flex-col items-center space-y-2 py-1">
        <div class="flex w-full items-center space-x-2 rounded bg-gray-800 px-3" v-if="selectedUsers.length > 1">
          <input
            v-model="channelName"
            class="h-8 w-full border-none bg-gray-800 text-xs font-normal leading-4 text-white outline-none focus:border-none"
            placeholder="Title chat"
            maxlength="64"
          />
        </div>
        <span v-if="channelNameError.length > 0 && selectedUsers.length > 1" class="text-sm text-red-400">
          {{ channelNameError }}
        </span>
        <div class="text-base font-semibold" :class="selectedUsers.length > 1 ? 'pt-4' : ''">Members</div>
        <ChatUserSelect
          :users="users"
          :selected-team-tab="selectedTeamTab"
          :searched-term="searchedTerm"
          @user-selected="$event.selected = true"
          @option-removed="$event.selected = false"
          @team-tab-selected="selectedTeamTab = $event"
        />
      </div>
      <div v-show="page == 2" class="flex-col items-center space-y-2 pt-4">
        <div>
          <ChatImage size="xl" :item="{ name: channelName, background_color: channelColor }"></ChatImage>
          <div class="my-2 mt-3 text-base font-semibold leading-6">{{ channelName }}</div>
          <span class="text-xs font-semibold uppercase leading-4 tracking-wider text-gray-400">Members</span>
          <div class="users_selection flex max-h-40 flex-wrap items-center overflow-y-scroll">
            <div
              v-for="user in selectedUsers"
              :key="user.id"
              class="mr-2 mt-2 flex h-8 flex-wrap items-center space-x-1 rounded bg-gray-800 p-1 px-3 hover:bg-gray-700"
            >
              <span class="text-xs font-medium leading-4">{{ user.name }}</span>
              <XMarkIcon
                class="h-3 w-3 text-gray-400"
                @click="
                  user.selected = false;
                  page = selectedUsers.length == 0 ? 1 : page;
                "
              />
            </div>
          </div>
        </div>
        <div>
          <div class="pb-1 pt-4 text-xs font-semibold uppercase tracking-wider text-gray-400">User Settings</div>
        </div>
        <ChannelPermissions
          :user-settings="settings"
          :externalteam="selectedExternalUsers.length > 0"
          @settings-updated="updateSettings($event)"
        ></ChannelPermissions>
      </div>
    </template>
    <template #footer>
      <div class="mt-2 flex w-full justify-end space-x-2 px-2 pt-5" :class="channelNameError ? 'mt-10' : ''">
        <div v-show="page == 2" class="mr-auto flex cursor-pointer items-center" @click="page = page - 1">
          <span class="text-sm font-medium leading-5 text-gray-100 hover:text-gray-300">Back</span>
        </div>
        <div class="flex items-center space-x-2">
          <BaseButton type="secondary" @press="$emit('close')">Cancel</BaseButton>
          <BaseButton
            v-if="page == 1"
            style="min-width: 95px"
            :disabled="selectedUsers.length < 1"
            @press="goToNextPage"
          >
            Next
          </BaseButton>
          <BaseButton :busy="buttonBusy" v-else @press="createChannel()">Confirm</BaseButton>
        </div>
      </div>
    </template>
  </BaseDialog>
</template>

<script setup>
import { onMounted, ref, computed, watch, reactive } from 'vue';
import ChatUserSelect from './ChatUserSelect.vue';
import ChannelPermissions from './ChatChannelPermissions.vue';
import ChatImage from './ChatImage.vue';
import { useMapGetter } from '@/store/map-state';
import { saveNotificationPreference, getColor } from '@/composeables/chat';
import useEmitter from '@/composeables/emitter';
import useHttp from '@/composeables/http';
import { XMarkIcon } from '@heroicons/vue/20/solid';

const { client, streamUser, streamUsers, session } = useMapGetter();
const $emitter = useEmitter();
const $http = useHttp();
const $emit = defineEmits(['close']);

const props = defineProps({
  channels: { type: Array, default: () => [] },
  searchedTerm: { type: String, default: null },
  searchedUser: { type: Object, default: null }
});

// SETTINGS
const settings = reactive({
  message_history_access: false,
  add_member_access: false,
  note: ''
});

function updateSettings(data) {
  Object.assign(settings, data);
}

// USERS
const users = ref([]);

const selectedUsers = computed(() => {
  return users.value.filter(x => x.selected);
});

const selectedExternalUsers = computed(() => {
  let users =
    selectedUsers.value
      .filter(user => (user.teams ? user.team_id != streamUser.value.team_id : false))
      .map(user => user.id) || [];
  return users;
});

function addMemberText(selectedTeamMembers) {
  let firstThreeMembers = selectedTeamMembers.slice(0, 3);
  let text = `{{user:${streamUser.value.id}}} added ${firstThreeMembers.map(u => `{{user:${u.id}}}`).join(', ')}`;
  if (firstThreeMembers.length === 3 && !(selectedTeamMembers.length - 3) <= 0) {
    text += ` and ${selectedTeamMembers.length - 3} other${selectedTeamMembers.length - 3 > 1 ? 's' : ''}`;
  }
  text += ' to the chat.';
  return text;
}

function messageMembers(teamMembers) {
  let data = [];
  data.push({ id: streamUser.value.id, name: streamUser.value.name });
  let firstThreeMembers = teamMembers.slice(0, 3);
  firstThreeMembers.forEach(m => {
    data.push({ id: m.id, name: m.name });
  });
  return data;
}

onMounted(() => {
  users.value = streamUsers.value.map(x => {
    return { ...x, selected: false };
  });
});

// SELECTED TEAM TAB
const selectedTeamTab = ref('externalteam');
onMounted(() => {
  if (props.searchedTerm) {
    selectedTeamTab.value = 'externalteam';
  }
  if (props.searchedUser) {
    users.value.find(x => x.id == props.searchedUser.id).selected = true;
    if (props.searchedUser.team_id == session.value.user.team_id.toString()) {
      selectedTeamTab.value = 'team';
    }
  }
});

// CHANNEL
const channelName = ref('');
const channelNameError = ref('');
const buttonBusy = ref(false);
const channelColor = ref(null);

watch(
  () => channelName.value,
  (newVal, oldVal) => {
    if (channelName.value) {
      channelNameError.value = '';
    }
  }
);

async function createChannel() {
  let id = Math.random().toString(16).slice(2);
  let channel_id = [channelName.value.replace(/[^\w]/g, '').substring(0, 64 - id.length - 1), id].join('_');
  buttonBusy.value = true;
  let selectedTeamMembers = selectedUsers.value.filter(user =>
    user.teams ? user.team_id == streamUser.value.team_id : false
  );
  let selectedExternalMembers = selectedExternalUsers.value;
  selectedTeamMembers.push(client.value.user);
  const channel = client.value.channel('messaging', channel_id, {
    team: 'messaging',
    background_color: channelColor.value,
    name: channelName.value.trim(),
    members: selectedTeamMembers.map(user => user.id),
    channel_admins: [client.value.user.id],
    message_history_access: settings.message_history_access,
    add_member_access: settings.add_member_access,
    note: settings.note,
    invited_users: selectedExternalMembers.length
      ? [
          {
            invited_users: selectedExternalMembers,
            invited_by: { id: streamUser.value.id, name: streamUser.value.name }
          }
        ]
      : []
  });
  await channel.watch();
  if (selectedExternalMembers.length) {
    await channel.inviteMembers(selectedExternalMembers);
    $http.put('/mute_channel', {
      channel_id: channel.id,
      user_ids: selectedExternalMembers
    });
  }
  await channel.sendMessage({
    text: `{{user:${streamUser.value.id}}} has created {{channel_name}}.`,
    activity_status: 'created',
    activity_members: [{ id: streamUser.value.id, name: streamUser.value.name }]
  });
  if (selectedTeamMembers.filter(x => x.id !== client.value.user.id).length) {
    channel.sendMessage({
      text: addMemberText(selectedTeamMembers.filter(x => x.id !== client.value.user.id)),
      activity_status: 'added',
      activity_members: messageMembers(selectedTeamMembers.filter(x => x.id !== client.value.user.id))
    });
  }
  saveNotificationPreference(
    selectedTeamMembers.map(x => x.id),
    channel,
    'all'
  );
  $emitter.$emit('channel:added', channel);
  resetModal();
  buttonBusy.value = false;
  $emit('close');
}

// PAGE
const page = ref(1);
function goToNextPage() {
  if (channelName.value.trim().length === 0 && selectedUsers.value.length > 1) {
    channelNameError.value = 'Please provide channel name.';
  } else if (
    props.channels.some(x => x.data.name && x.data.name.toLowerCase() === channelName.value.trim().toLowerCase())
  ) {
    channelNameError.value = 'Channel with this name already exists.';
  } else if (selectedUsers.value.length == 1) {
    $emitter.$emit('send-dm', selectedUsers.value[0]);
    $emit('close');
  } else {
    if (!channelColor.value) {
      channelColor.value = getColor();
    }
    page.value = page.value + 1;
  }
}

// OTHER
const searchText = ref('');
const modalTitle = computed(() => {
  return page.value == 1 ? 'Create New Chat' : 'Confirm Chat Details';
});

function resetModal() {
  channelName.value = searchText.value = '';
  users.value = users.value.map(user => {
    return { ...user, selected: false };
  });
  Object.assign(settings, {
    message_history_access: false,
    add_member_access: false,
    note: ''
  });
}
</script>
